<script lang="ts">
  import * as ace from 'brace'
  import 'brace/mode/latex'
  import 'brace/theme/textmate'

  import { exercicesParams, darkMode } from './stores/generalStore'
  import {
    mathaleaGetExercicesFromParams,
    mathaleaUpdateExercicesParamsFromUrl,
    mathaleaUpdateUrlFromExercicesParams
  } from '../lib/aleatex.js'
  import type TypeExercice from '../exercices/ExerciceTs.js'
  import Footer from './Footer.svelte'
  import NavBar from './header/NavBar.svelte'
  import Latex, {
    type Exo,
    type picFile,
    buildImagesUrlsList,
    getExosContentList,
    getPicsNames,
    doesLatexNeedsPics,
    makeImageFilesUrls
  } from '../lib/Latex'
  import Button from './forms/Button.svelte'
  import ButtonToggle from './forms/ButtonToggle.svelte'  
  import CompilateurLatexOnline from './forms/CompilateurLatexOnline.svelte'
  import { afterUpdate, onMount, tick } from 'svelte'
  import ModalMessageBeforeAction from './modal/ModalMessageBeforeAction.svelte'
  import { downloadTexWithImagesZip, downloadZip } from '../lib/files'
  import SimpleCard from './ui/SimpleCard.svelte'  
  import FormRadio from './forms/FormRadio.svelte';
  import { randint } from '../modules/outils';

  let nbVersions = 1
  let title = ''
  let reference = ''
  let subtitle = ''
  let dys = false
  let theme = ''
  let sousTheme = ''
  let date = ''
  let niveau = ''
  let classe = ''
  let corrige : 'CorrigeFin' | 'CorrigeApres' | '' = 'CorrigeFin'
  let twocolumn = false
  let beamerTwocolumn = true  
  let didactiques = false
  export let style:    
    | 'PfMFiche'
    | 'PfMCan'
    | 'PfMBeamer'
    | 'PfMAmc' = 'PfMFiche'
  export let currentTitlePage:
    | 'LaTeX'
    | 'LaTeX - Course Aux Nombres'
    | 'LaTeX - Beamer'
    | 'LaTeX - AMC' = 'LaTeX'
  let exercices: TypeExercice[]
  let contents = { content: '', contentCorr: '' }
  let isExerciceStaticInTheList = false
  let downloadPicsModal: HTMLElement
  let picsWanted: boolean
  let picsNames: picFile[][] = []
  let exosContentList: Exo[] = []
  const latex = new Latex()
  
  async function initExercices () {
    picsWanted = false
    mathaleaUpdateExercicesParamsFromUrl()
    exercices = await mathaleaGetExercicesFromParams($exercicesParams)    
    for (const exercice of exercices) {
      // picsWanted = doesLatexNeedsPics({ content: exercice.content, contentCorr: '' })
      picsWanted = doesLatexNeedsPics(contents)
      if (exercice.typeExercice === 'statique') {
        isExerciceStaticInTheList = true
        break
      }
    }
  }  
  
  async function updateLatex () {
    contents = await latex.getContents(
      style,
      nbVersions,
      dys,
      theme,
      sousTheme,
      date,
      niveau,
      classe,
      corrige,
      twocolumn,
      beamerTwocolumn,
      didactiques,
      title,
      subtitle,
      reference
    )
    return contents
  }
  
  afterUpdate(() => {    
  })
  
  onMount(() => {
		// Write you code here....
		// This is executed when the component is loaded into the DOM        
		// Optional return function    
    mathaleaUpdateUrlFromExercicesParams($exercicesParams)
    updateEditor(true)
    downloadPicsModal = document.getElementById(
      'downloadPicsModal'
    ) as HTMLElement
    document.addEventListener('updateAsyncEx', updateLatex)
		return () => {
			// This is executed when the component is removed from the DOM
      // console.log('Suppression du composant Latex')
		}
	})

  function resetIframe () : HTMLElement {
    const iframe = document.getElementById('pre0ifr') as HTMLElement
    const parent = iframe.parentElement
    parent?.removeChild(iframe)
    const iframe2 = document.createElement('iframe')
    iframe2.setAttribute('title', 'output')
    iframe2.setAttribute('width', '100%')
    iframe2.setAttribute('height', '100%')
    iframe2.setAttribute('id', 'pre0ifr')
    iframe2.setAttribute('name', 'pre0ifr')
    parent?.appendChild(iframe2)
    return iframe2
  }
  
  /**
   * Mets à jour le code dans l'éditeur de la page d'export LaTeX
   */
   async function updateEditor(firstLoad : boolean) {
      exercices = await mathaleaGetExercicesFromParams($exercicesParams)
      contents = await latex.getContents(
        style,
        nbVersions,
        dys,        
        theme,
        sousTheme,
        date,
        niveau,
        classe,
        corrige,
        twocolumn,
        beamerTwocolumn,
        didactiques,
        title,
        subtitle,
        reference
      )
      const picsWanted = doesLatexNeedsPics(contents)
      const exosContentList = getExosContentList(latex.exercices)
      const picsNames = getPicsNames(exosContentList)
      const imagesUrls : string[] = picsWanted
        ? buildImagesUrlsList(exosContentList, picsNames)
        : []

      const editor = ace.edit('editor')
      editor.getSession().setMode('ace/mode/latex')
      editor.getSession().setNewLineMode('unix')
      editor.setTheme('ace/theme/textmate')
      editor.setShowPrintMargin(false)
      const t = editor.getValue()
      // On n'affiche pas le préambule
      // On sélectionne et on affiche uniquement le code modifiable dans l'éditeur LaTeX via une regex
      let corpsTexRegExp = new RegExp (/\\begin{document}([^]*?)\\end{document}/gm)
      // Une regex pour récupérer l'ouverture de l'environnement Maquette avec ses paramètres
      let maquetteRegExp = new RegExp(/^\\begin{Maquette}(.*)/gm)
      // Une regex pour récupérer les environnements Consignes
      let didactiquesRegExp = new RegExp(/\\begin{Consignes}([^]*?)\\end{Consignes}\n/gm)
      // Une regex pour récupérer l'ouverture des environnements exercice
      let exercicesRegExp = new RegExp(/\\begin{exercice}(.*)/gm)
      // Une regex pour récupérer les environnements exercices
      let environnementsExosRegExp = new RegExp(/\\begin{exercice}([^]*?)\\end{exercice}\n/gm)
      // On récupère le contenu mis à jour de la Maquette avec ses options     
      console.info(contents.content.match(corpsTexRegExp)[0])
      console.info(contents.content.match(corpsTexRegExp)[0].match(maquetteRegExp))
      const majMaquette = contents.content.match(corpsTexRegExp)[0].match(maquetteRegExp)[0]
      // Au premier chargement on ne modifie rien
      let majCode
      if (firstLoad) {
        // On charge le code initial auquel on supprime les environnements Consignes.
        // On remplace toutes le occurences trouvées y compris les doublons si il y a plusieurs fois le même exercice        
        if (contents.content.match(didactiquesRegExp)) {          
          majCode = contents.content.match(corpsTexRegExp)[0].replaceAll(contents.content.match(didactiquesRegExp)[0],'')
          for (let i = 1; i<contents.content.match(exercicesRegExp).length; i++) {
            // On remplace toutes le occurences trouvées y compris les doublons si il y a plusieurs fois le même exercice
            majCode = majCode.match(corpsTexRegExp)[0].replaceAll(contents.content.match(didactiquesRegExp)[i],'')            
          }
        } else {
          majCode = contents.content.match(corpsTexRegExp)[0]
        }        
        editor.setValue(majCode)
        !firstLoad 
      } else {
        // sinon on modifie la maquette
        majCode = editor.getValue().match(corpsTexRegExp)[0].replace(editor.getValue().match(corpsTexRegExp)[0].match(maquetteRegExp)[0],majMaquette)
        // On ajoute aussi les environnements Consignes au besoin
        if (didactiques === true) {
          if (!editor.getValue().match(didactiquesRegExp)) {
            for (let i = 0; i<contents.content.match(exercicesRegExp).length; i++) {
              majCode = majCode.replace(editor.getValue().match(environnementsExosRegExp)[i],editor.getValue().match(environnementsExosRegExp)[i].replace(editor.getValue().match(exercicesRegExp)[i],`${contents.content.match(exercicesRegExp)[i]}\n\t\t${contents.content.match(didactiquesRegExp)[i].slice(0,-1)}`))
            }
            editor.setValue(majCode)
          } else {
            console.log('là')
          }          
        } else {
          if (editor.getValue().match(didactiquesRegExp)) {
            // On remplace toutes le occurences trouvées y compris les doublons si il y a plusieurs fois le même exercice
            majCode = editor.getValue().match(corpsTexRegExp)[0].replaceAll(editor.getValue().match(didactiquesRegExp)[0],'\n'.slice(0,-1))
            for (let i = 1; i<editor.getValue().match(exercicesRegExp).length; i++) {
              // On remplace toutes le occurences trouvées y compris les doublons si il y a plusieurs fois le même exercice
              majCode = majCode.match(corpsTexRegExp)[0].replaceAll(editor.getValue().match(didactiquesRegExp)[i],'\n'.slice(0,-1))
            }
          }       
          editor.setValue(majCode)
        }
        editor.setValue(majCode)
      }
      editor.gotoLine(0)

      resetIframe()
      
      await tick()
  }

  /* ============================================================================
  *
  *                Modal pour le téléchargement des figures
  *
  =============================================================================== */
  // click en dehors du moda de téléchargement des figures le fait disparaître
  window.onclick = function (event) {
    if (event.target === downloadPicsModal) {
      downloadPicsModal.style.display = 'none'
    }
  }

  /**
   * Gérer le téléchargement des images dans une archive `images.zip` lors du clic sur le bouton du modal
   * @author sylvain
   */
  function handleActionFromDownloadPicsModal () {
    const imagesFilesUrls = makeImageFilesUrls(exercices)
    downloadZip(imagesFilesUrls, 'images.zip')
    downloadPicsModal.style.display = 'none'
  }

  /**
   * Gérer l'affichage du modal : on donne la liste des images par exercice
   */
  function handleDownloadPicsModalDisplay () {
    exosContentList = getExosContentList(exercices)
    picsNames = getPicsNames(exosContentList)
    downloadPicsModal.style.display = 'block'
  }
  //= ===================== Fin Modal figures ====================================

  initExercices()

  $: {
    (async () => {
      try {
        contents = await latex.getContents(
          style,
          nbVersions,
          dys,
          theme,
          sousTheme,
          date,
          niveau,
          classe,
          corrige,
          twocolumn,
          beamerTwocolumn,
          didactiques,
          title,
          subtitle,
          reference
        )        
      } catch (error) {
        console.error('Erreur lors de la création du code LaTeX :', error)
        contents = {
          content: '% Erreur à signaler',
          contentCorr: '% Erreur à signaler'
        }
      }
    })()        
  }
</script>

<main
  class="bg-aleatex-canvas dark:bg-aleatexdark-canvas {$darkMode.isActive
    ? 'dark'
    : ''}"
>
  <NavBar subtitle="{currentTitlePage}" subtitleType="export" />

  <section class="px-4 py-0 md:py-0 bg-aleatex-canvas dark:bg-aleatexdark-canvas">
    <div class="grid grid-cols-1 grid-rows-1 md:grid-cols-2 xl:grid-cols-2 gap-8">      
      <SimpleCard icon={''} title={'Code LaTeX ?'}>
        <div class="flex flex-col w-full justify-start items-start space-y-2">
          <div class="grid grid-cols-1 grid-rows-1 md:grid-cols-1 xl:grid-cols-1 gap-8" >
            <p class="mt-4">Récupérer le code LaTeX pour le compiler localement sur un ordinateur.</p>
            <Button
              class="px-2 py-1 rounded-md"
              idLabel="downloadFullArchive"
              on:click={() => {
                const filesInfo = {
                  style,
                  nbVersions,
                  dys,                  
                  theme,
                  sousTheme,
                  date,
                  niveau,
                  classe,
                  corrige,
                  twocolumn,
                  beamerTwocolumn,
                  didactiques,
                  title,
                  reference,
                  subtitle
                }
                downloadTexWithImagesZip('aleaTeX', latex, filesInfo)
              }}
              title="Télécharger l'archive complète"
            />
            <Button
              class="inline-block px-2 py-1 rounded-md"
              idLabel="downloadPicsButton"
              on:click={handleDownloadPicsModalDisplay}
              title="Télécharger uniquement les figures"
              isDisabled={!picsWanted}
            />            
          </div>
          <ModalMessageBeforeAction
            modalId="downloadPicsModal"
            modalButtonId="downloadPicsModalButton"
            modalButtonTitle="Télécharger l'archive"
            icon="bxs-file-png"
            classForButton="px-2 py-1 rounded-md"
            on:action={handleActionFromDownloadPicsModal}
          >
            <span slot="header">Figures</span>
          </ModalMessageBeforeAction>
        </div>
      </SimpleCard>
      <SimpleCard icon={''} title={'Distribution LaTeX'}>
        <div class="grid grid-cols-1 grid-rows-1 md:grid-cols-1 xl:grid-cols-1 gap-8" >
          <p class="mt-4">
            Il est préféreable <em class="text-aleatex-warn-darkest dark:text-aleatexdark-warn-darkest font-bold">d'installer localement une distribution TeX Live</em> et de la <em class="text-aleatex-warn-darkest dark:text-aleatexdark-warn-darkest font-bold">maintenir à jour</em> pour disposer des dernières versions des packages utilisés et nécessaires.
          </p>
          <a 
            class = 'text-center px-2 py-1 rounded-md text-aleatex-canvas dark:text-aleatexdark-canvas bg-aleatex-action dark:bg-aleatexdark-action hover:bg-aleatex-action-lightest dark:hover:bg-aleatexdark-action-lightest'
            href="https://www.tug.org/texlive/" target="_blank"
          >
          <i class="bx bx-link"></i> https://www.tug.org/texlive/ 
          </a>
        </div>
      </SimpleCard>
    </div>
    <div class="grid grid-cols-1 grid-rows-1 md:grid-cols-1 xl:grid-cols-1 gap-8">
      <p class="mt-12 text-center md:mt-8 text-aleatex-struct dark:text-aleatexdark-struct">
        <b>Code LaTeX - Compilable et modifiable ici pour récupérer un document PDF</b>
        <br>
        CTRL+S permet de relancer la compilation après modification du code dans l'éditeur LaTeX      
      </p>        
    </div>
    <div class="flex justify-center">
      <div class="grid inline-grid grid-cols-1 grid-rows-1 md:grid-cols-3 xl:grid-cols-3 gap-8">
        <div class="w-full space-y-2 p-4 mx-auto border">
          {#if style === 'PfMFiche' || style === 'PfMAmc'}
          <ButtonToggle
            id={'Twocolumn'}
            titles={['Mise en forme twocolumn', 'Mise en forme onecolumn']}
            bind:value={twocolumn}
            on:toggle={(event) => updateEditor(false)}
          />
          {/if}
          {#if style === 'PfMBeamer'}
          <ButtonToggle
            id={'BeamerTwocolumn'}
            titles={['Mise en forme twocolumn', 'Mise en forme onecolumn']}
            bind:value={beamerTwocolumn}
            on:toggle={(event) => updateEditor(false)}
          />
          {/if}
          {#if style !== 'PfMBeamer'}
          <ButtonToggle
              id={'Dys'}
              titles={['Mise en forme pour les élèves Dys', 'Mise en forme pour les élèves non Dys']}
              bind:value={dys}
              on:toggle={(event) => updateEditor(false)}
          />
          {/if}
          {#if style !== 'PfMCan' && style !== 'PfMBeamer'}
          <ButtonToggle
            id={'Didactiques'}
            titles={['Avec indications didactiques', 'Sans indications didactiques']}
            bind:value={didactiques}
            on:toggle={(event) => updateEditor(false)}
          />
          {/if}
          {#if style !== 'PfMBeamer' && style !== 'PfMAmc'}
          <div
          class="pl-2 inline-block text-sm font-light text-aleatex-corpus dark:text-aleatexdark-corpus text-opacity-70"
          >
            Nombre de versions : 
          </div>
          <input
            type="number"
            id="export-latex-nb-versions-input"
            class="border-1 h-6 w-1/5 border-aleatex-action dark:border-aleatexdark-action focus:border-aleatex-action-lightest dark:focus:border-aleatexdark-action-lightest focus:outline-0 focus:ring-0 focus:border-1 bg-aleatex-canvas dark:bg-aleatexdark-canvas text-sm text-aleatex-corpus-light dark:text-aleatexdark-corpus-light"
            name="numberOfVersions"
            maxlength="2"
            min="1"
            max="20"
            bind:value={nbVersions}
            on:change={(event) => updateEditor(false)}
          />
          {/if}
          </div>          
          <div class="w-full space-y-2 p-4 mx-auto border">
            {#if style !== 'PfMCan'}    
            <input
              type="text"
              id="export-latex-titre-input"
              class="border-1 h-6 w-full border-aleatex-action dark:border-aleatexdark-action focus:border-aleatex-action-lightest dark:focus:border-aleatexdark-action-lightest focus:outline-0 focus:ring-0 focus:border-1 bg-aleatex-canvas dark:bg-aleatexdark-canvas text-sm text-aleatex-corpus-light dark:text-aleatexdark-corpus-light"
              placeholder={'Thème'}
              bind:value={theme}
              on:change={(event) => updateEditor(false)}
            />
            <br>
            {/if}
            {#if style === 'PfMBeamer'}
            <input
              type="text"
              id="export-latex-titre-input"
              class="border-1 h-6 w-full border-aleatex-action dark:border-aleatexdark-action focus:border-aleatex-action-lightest dark:focus:border-aleatexdark-action-lightest focus:outline-0 focus:ring-0 focus:border-1 bg-aleatex-canvas dark:bg-aleatexdark-canvas text-sm text-aleatex-corpus-light dark:text-aleatexdark-corpus-light"
              placeholder={'Sous-Thème'}
              bind:value={sousTheme}
              on:change={(event) => updateEditor(false)}
            />
            <br>
            {/if}          
            <!--  -->
            <input
              type="text"
              id="export-latex-titre-input"
              class="border-1 h-6 w-full border-aleatex-action dark:border-aleatexdark-action focus:border-aleatex-action-lightest dark:focus:border-aleatexdark-action-lightest focus:outline-0 focus:ring-0 focus:border-1 bg-aleatex-canvas dark:bg-aleatexdark-canvas text-sm text-aleatex-corpus-light dark:text-aleatexdark-corpus-light"
              placeholder={'Niveau'}
              bind:value={niveau}
              on:change={(event) => updateEditor(false)}
            />
            <br>
            <!--  -->
            {#if style !== 'PfMAmc'}
            <input
              type="text"
              id="export-latex-titre-input"
              class="border-1 h-6 w-full border-aleatex-action dark:border-aleatexdark-action focus:border-aleatex-action-lightest dark:focus:border-aleatexdark-action-lightest focus:outline-0 focus:ring-0 focus:border-1 bg-aleatex-canvas dark:bg-aleatexdark-canvas text-sm text-aleatex-corpus-light dark:text-aleatexdark-corpus-light"
              placeholder={'Classe'}
              bind:value={classe}
              on:change={(event) => updateEditor(false)}
            />
            <br>
            {/if}
            <!--  -->
            <input
              type="text"
              id="export-latex-titre-input"
              class="border-1 h-6 w-full border-aleatex-action dark:border-aleatexdark-action focus:border-aleatex-action-lightest dark:focus:border-aleatexdark-action-lightest focus:outline-0 focus:ring-0 focus:border-1 bg-aleatex-canvas dark:bg-aleatexdark-canvas text-sm text-aleatex-corpus-light dark:text-aleatexdark-corpus-light"
              placeholder={'Date'}
              bind:value={date}
              on:change={(event) => updateEditor(false)}
            />
          </div>
          {#if style !== 'PfMAmc'}
            <div class="w-full space-y-2 p-4 mx-auto border">
              <div
                class="pl-2 pb-2 font-bold text-aleatex-struct-light dark:text-aleatexdark-struct-light"
              >
                Options pour le corrigé
              </div>
              {#if style === 'PfMFiche'}
                <FormRadio
                  title="Correction"
                  bgColor="bg-aleatex-canvas-dark"
                  orientation={'col'}
                  bind:valueSelected={corrige}
                  labelsValues={[
                    { label: 'Corrigé à la fin du sujet', value: 'CorrigeFin' },
                    { label: 'Corrigé après chaque exercice', value: 'CorrigeApres' },
                    { label: 'Pas de corrigé', value: '' },
                    { label: 'Corrigé seul', value: 'CorrigeSeul' }
                  ]}
                  on:newvalue={(event) => {              
                    updateEditor(false)
                  }}
                />
              {/if}
              {#if style === 'PfMCan'}
                <FormRadio
                  title="Correction"
                  bgColor="bg-aleatex-canvas-dark"
                  orientation={'col'}
                  bind:valueSelected={corrige}
                  labelsValues={[
                    { label: 'Corrigé à la fin du sujet', value: 'CorrigeFin' },
                    { label: 'Corrigé au début du sujet', value: 'CorrigeFin,CorrigeInverse' },
                    { label: 'Pas de corrigé', value: '' }
                  ]}
                  on:newvalue={() => {
                    updateEditor(false)
                  }}
                />
              {/if}
              {#if style === 'PfMBeamer'}            
                <FormRadio
                  title="Correction"
                  bgColor="bg-aleatex-canvas-dark"
                  orientation={'col'}
                  bind:valueSelected={corrige}
                  labelsValues={[
                    { label: 'Corrigé à la fin du sujet', value: 'CorrigeFin' },
                    { label: 'Pas de corrigé', value: '' }
                  ]}
                  on:newvalue={() => {
                    updateEditor(false)
                  }}
                />
              {/if}
            </div>
          {/if}
      </div>
    </div>
    <CompilateurLatexOnline
      {latex}
      latexFileInfos={{
        style,
        nbVersions,
        dys,
        theme,
        sousTheme,
        date,
        niveau,
        classe,
        corrige,
        twocolumn,
        beamerTwocolumn,
        didactiques,
        title,        
        subtitle,
        reference
      }}
    />
  </section>
  <footer>
    <Footer />
  </footer>
</main>

<style>
  footer {
    margin-top: auto;
  }
</style>
