<svg
  width="32"
  height="32"
  viewBox="0 0 8.5 8.5"
  xmlns="http://www.w3.org/2000/svg"
  class={$$props.class}
  fill="currentColor"
>
  <defs id="defs504" />
  <g id="layer1">
    <g
      id="g1669"
      transform="matrix(1.108044,0,0,1.0300717,-0.36352999,-0.1273056)"
    >
      <g
        aria-label="A"
        id="text313"
        style="font-stretch:ultra-condensed;font-size:7.54751px;line-height:1.25;font-family:'League Gothic';-inkscape-font-specification:'League Gothic, Ultra-Condensed';text-align:end;text-anchor:end;stroke-width:0.353789"
        transform="matrix(1.2141816,0,0,1.0476377,-0.50895982,-0.00588555)"
      >
        <path
          d="m 1.3966823,5.6710072 h 0.6113484 l 0.083023,-1.1321266 h 0.3471854 l 0.083023,1.1321266 H 3.1326097 L 2.634474,0.1235872 H 1.9023655 Z M 2.264646,1.4745915 2.3854062,3.6784645 H 2.1514334 Z"
          id="path1647"
        />
      </g>
      <g
        aria-label="E"
        id="text371"
        style="font-stretch:ultra-condensed;font-size:9.4344px;line-height:1.25;font-family:'League Gothic';-inkscape-font-specification:'League Gothic, Ultra-Condensed';text-align:end;text-anchor:end;stroke-width:0.353789"
      >
        <path
          d="M 4.4998348,1.4087978 V 8.3430815 H 6.0659451 V 7.2958631 H 5.2828899 V 5.3901144 h 0.566064 V 4.2768552 H 5.2828899 V 2.4560161 H 6.0659451 V 1.4087978 Z"
          id="path1653"
        />
      </g>
      <g
        aria-label="L"
        id="text309"
        style="font-stretch:ultra-condensed;font-size:9.4344px;line-height:1.25;font-family:'League Gothic';-inkscape-font-specification:'League Gothic, Ultra-Condensed';text-align:end;text-anchor:end;stroke-width:0.353789"
        transform="translate(-0.16940966)"
      >
        <path
          d="M 0.4974923,0.12358906 V 7.0578728 h 1.5944135 v -0.94344 H 1.2805475 V 0.12358906 Z"
          id="path1644"
        />
      </g>
      <g
        aria-label="T"
        id="text367"
        style="font-stretch:ultra-condensed;font-size:9.4344px;line-height:1.25;font-family:'League Gothic';-inkscape-font-specification:'League Gothic, Ultra-Condensed';text-align:end;text-anchor:end;stroke-width:0.353789"
      >
        <path
          d="M 2.8936626,1.1708074 H 3.4691609 V 7.0578728 H 4.2522161 V 1.1708074 H 4.8277145 V 0.12358906 H 2.8936626 Z"
          id="path1650"
        />
      </g>
      <g
        aria-label="X"
        id="text375"
        style="font-stretch:ultra-condensed;font-size:9.4344px;line-height:1.25;font-family:'League Gothic';-inkscape-font-specification:'League Gothic, Ultra-Condensed';text-align:end;text-anchor:end;stroke-width:0.353789"
      >
        <path
          d="M 6.9125229,5.1426897 7.2049893,7.0578728 H 7.9691756 L 7.5163245,4.6049289 C 7.4408493,4.2086841 7.3748085,3.8030049 7.2993333,3.4161945 L 7.9597412,0.12358906 H 7.1861205 C 7.1012109,0.68021864 6.9974325,1.2462826 6.9125229,1.8029122 L 6.6389253,0.12358906 H 5.8653045 L 6.1955085,1.774609 c 0.094344,0.518892 0.2264256,1.1226935 0.330204,1.6415855 L 5.8558701,7.0578728 h 0.7641864 z"
          id="path1656"
        />
      </g>
    </g>
  </g>
</svg>
