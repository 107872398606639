import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
import { saveAs } from 'file-saver'
import type Latex from './Latex'
import { buildImagesUrlsList, buildDatsUrlsList, buildPythonsUrlsList, doesLatexNeedsPics, doesLatexNeedsDats, doesLatexNeedsPythons, getExosContentList, getPicsNames, type LatexFileInfos, getDatsNames, getPythonsNames } from './Latex'
import styleKawasakiFicheAmc from './latex/styleKawasakiFicheAmc.tex?raw'
import preambule from './latex/preambule.tex?raw'
import paquetsAdditionnels from './latex/paquetsAdditionnels.tex?raw'
import cedricPierquet from './latex/cedricPierquet.tex?raw'
import christophePoulain from './latex/christophePoulain.tex?raw'
import sebastienLozano from './latex/sebastienLozano.tex?raw'
import pourBeamer from './latex/pourBeamer.tex?raw'
import pourAmc from './latex/pourAmc.tex?raw'
import listeElevesFactice from './latex/listeElevesFactice.csv?raw'
// A supprimer après maj PfM
// import PfM from './latex/ProfMaquette.sty?raw'
// import PfC from './latex/ProfCollege.sty?raw'
// import PfCStuffTeX from './latex/PfCStuffTeX.tex?raw'
// import PfCStuffMP from './latex/PfCStuffMP.mp?raw'

import Exercice from 'src/exercices/ExerciceTs'

export function downloadZip (filesUrls: string[], zipFileName: string) {
  const zip = new JSZip()
  let count = 0
  filesUrls.forEach((url) => {
    JSZipUtils.getBinaryContent(url, (err, data) => {
      if (err) {
        throw err
      }
      const splitUrl = url.split('/')
      const fileName = splitUrl[splitUrl.length - 1]
      zip.file(fileName, data, { binary: true })
      count++
      if (count === filesUrls.length) {
        zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, zipFileName)
        })
      }
    })
  })
}

function urlToPromise (url: string) {
  return new Promise(function (resolve, reject) {
    JSZipUtils.getBinaryContent(url, function (err, data) {
      if (err) {
        reject(err)
      } else {
        resolve(data)
      }
    })
  })
}
/**
   * Récupère les exercies depuis les paramètres de l'url et vérifie s'il y a des exercices de niveau BAC
   */
function isBacIn (exercices: Exercice[]) {
  let myBool = false
  // console.info(exercices)
  let ex = 0
  do {
    if (exercices[ex].examen === 'BAC') {
      myBool = true
    }
    ex += 1
  } while (ex < exercices.length)
  // console.info(isBacIn)
  return myBool
}

/**
 * Construit l'archive ZIP contenant le code LaTeX et tous les fichiers images nécessaires pour la compilation du code LaTeX
 * @param {string} zipFileName nom donné pour l'archive
 * @param {Latex} latex objet Latex contenant les données des exercices
 * @param {LatexFileInfos} filesInfo paramètres du fichier LaTeX à générer
 * @author Sébastien LOZANO et Christophe POULAIN
 */
export async function downloadTexWithImagesZip (zipFileName: string, latex: Latex, latexFileInfos: LatexFileInfos) {
  const zip = new JSZip()
  // const text = await latex.getFile(latexFileInfos)
  // console.log('text', text)
  // console.log('latexFileinfos', latexFileInfos)
  const contents = latex.getContents(
    latexFileInfos.style,
    latexFileInfos.nbVersions,
    latexFileInfos.dys,
    latexFileInfos.theme,
    latexFileInfos.sousTheme,
    latexFileInfos.date,
    latexFileInfos.niveau,
    latexFileInfos.classe,
    latexFileInfos.corrige,
    latexFileInfos.twocolumn,
    latexFileInfos.beamerTwocolumn,
    latexFileInfos.didactiques,
    latexFileInfos.title,
    latexFileInfos.subtitle,
    latexFileInfos.reference
  )
  // console.log('contents', contents)
  const withImages = doesLatexNeedsPics(await contents)
  // console.log('pics ' + withImages)
  const withDats = doesLatexNeedsDats(await contents)
  // console.log('dats ' + withDats)
  const withPythons = doesLatexNeedsPythons(await contents)
  // console.log('pythons ' + withPythons)
  // console.log('latex.exercices', latex.exercices)
  const exosContentList = getExosContentList(latex.exercices)
  // console.log('exosContentList', exosContentList)
  const picsNames = getPicsNames(exosContentList)
  const datsNames = getDatsNames(exosContentList)
  const pythonsNames = getPythonsNames(exosContentList)
  // Une regex pour récupérer les environnements Consignes
  const didactiquesRegExp = /\\begin{Consignes}([^]*?)\\end{Consignes}/gm
  // On ajoute le documentclass
  let codeTex = ''
  switch (latexFileInfos.style) {
    case 'PfMCan':
    case 'PfMFiche':
      codeTex += `%!TEX lualatex\n\\documentclass[french${latexFileInfos.twocolumn === true ? ',twocolumn' : ''}]{article}\n`
      break
    case 'PfMBeamer':
      codeTex += '%!TEX lualatex\n\\PassOptionsToPackage{luatex}{hyperref}\n\\documentclass[french,t]{beamer}\n'
      break
    case 'PfMAmc':
      codeTex += '%!TEX lualatex\n\\documentclass[12pt,a4paper,french]{article}\n'
      break
    default:
      console.info(`Cas ${latexFileInfos.style} non prévu pour le style LaTeX`)
  }
  // On ajoute le preambule
  switch (latexFileInfos.style) {
    case 'PfMCan':
      codeTex += preambule
        .replace('\\usepackage{ProfMaquette}', '\\usepackage[CAN]{ProfMaquette}')
        .replace('a4paper', 'a5paper')
        .replace('\\input{pourAmc.tex}', '% On supprime l\'appel au fichier pourAmc.tex')
        .replace('\\input{./commandes/pourAmc.tex}', '% On supprime l\'appel au fichier pourAmc.tex')
      break
    case 'PfMFiche':
      codeTex += preambule
        .replace('\\input{pourAmc.tex}', '% On supprime l\'appel au fichier pourAmc.tex')
        .replace('\\input{./commandes/pourAmc.tex}', '% On supprime l\'appel au fichier pourAmc.tex')
      break
    case 'PfMBeamer':
      codeTex += preambule
        .replace('\\usepackage[a4paper,margin=1cm,nohead,includefoot]{geometry}', '%')
        .replace('\\setlength{\\parindent}{0pt}', '%')
        .replace('\\pagestyle{empty}', '%')
        .replace('\\input{pourAmc.tex}', '% On supprime l\'appel au fichier pourAmc.tex')
        .replace('\\input{./commandes/pourAmc.tex}', '% On supprime l\'appel au fichier pourAmc.tex')
      break
    case 'PfMAmc':
      codeTex += preambule
        .replace(
          '\\usepackage[a4paper,margin=1cm,nohead,includefoot]{geometry}',
          '%'
        )
        .replace('\\setlength{\\parindent}{0pt}', '%')
        .replace('\\pagestyle{empty}', '%')
      break
    default:
      console.info(`Cas ${latexFileInfos.style} non prévu pour le style LaTeX`)
  }
  // On gère la Maquette et le nombre de versions
  codeTex += '\n\\begin{document}'
  switch (latexFileInfos.style) {
    case 'PfMCan':
    case 'PfMFiche':
      codeTex += `
      \n\\xintFor* #1 in {\\xintSeq{1}{${latexFileInfos.nbVersions}}}\\do{
      \\begin{Maquette}[${latexFileInfos.style === 'PfMCan' ? 'CAN' : 'Fiche'}${latexFileInfos.corrige === '' ? '' : ','}${latexFileInfos.corrige}${latexFileInfos.dys === true ? ',Dys' : ''}]{Theme={${latexFileInfos.theme || 'Exercices'}},Niveau={${latexFileInfos.niveau || ''}},Classe={${latexFileInfos.classe || ''}},Date={${latexFileInfos.nbVersions > 1 ? `${latexFileInfos.date !== '' ? latexFileInfos.date + ' - v#1' : 'v#1'}` : `${latexFileInfos.date !== '' ? latexFileInfos.date : ''}`}}}`
      break
    case 'PfMBeamer':
      codeTex += `\n\\begin{Maquette}[${latexFileInfos.corrige}]{Theme={${latexFileInfos.theme || 'Exercices'}},SousTheme={${latexFileInfos.sousTheme || ''}},Niveau={${latexFileInfos.niveau || ''}},Classe={${latexFileInfos.classe || ''}},Date={${latexFileInfos.date !== '' ? latexFileInfos.date : ''}}}`
      // Pour Beamer, gestion de l'option double colonne
      if (latexFileInfos.beamerTwocolumn) {
        codeTex += '\n\\begin{multicols}{2}'
      }
      break
    case 'PfMAmc':
      codeTex += `\n\\csvreader[head to column names]{listeElevesFactice.csv}{}{%\n\\exemplaire{1}{%
      \n\\begin{Maquette}[AMC${latexFileInfos.dys === true ? ',Dys' : ''}]{Theme={${latexFileInfos.theme || 'Exercices'}},,Niveau={${latexFileInfos.niveau || ''}},Date={${latexFileInfos.date !== '' ? latexFileInfos.date : '\\today'}}}`
      // Pour AMC, gestion de l'option double colonne
      if (latexFileInfos.twocolumn) {
        codeTex += '\n\\begin{multicols}{2}'
      }
      break
    default:
      console.info(`Cas ${latexFileInfos.style} non prévu pour le style LaTeX`)
  }
  const latexExos = latex.exercices
  console.log('latexExos', latexExos)
  // console.info(isBacIn(latexExos))
  let i = 1
  let currentData = ''
  latexExos.forEach(function (exo) {
    // console.log('exo', exo)
    if (Object.prototype.hasOwnProperty.call(exo, 'typeExercice')) {
      if (exo.typeExercice === 'statique') {
        codeTex += `\n\t% @see : https://aleatex.mathslozano.fr/?uuid=dnb_${exo.annee}${exo.mois === undefined ? '' : `_${exo.mois}`}_${exo.lieu}_ex${exo.numeroInitial}`
        codeTex += `\n\t \\input{./exercices/ex${i}}`
        currentData = `\n${exo.content}`
        currentData += '%Correction'
        currentData += `\n${exo.contentCorr}`
        zip.folder('exercices')?.file('ex' + i + '.tex', currentData)
        i += 1
      } else {
        // console.log(exo.listeArguments[0])
        codeTex += `\n\t% @see : https://aleatex.mathslozano.fr/?uuid=${exo.uuid}&id=${exo.id}&n=${exo.nbQuestions}&d=${exo.duration}&alea=${exo.seed}&cols=${exo.nbCols}`
        codeTex += `\n\t \\input{./exercices/ex${i}}`
        if (latexFileInfos.style === 'PfMBeamer' && i !== latexExos.length) {
          codeTex += '\n\\pause'
        }
        if (latexFileInfos.didactiques === false && exo.contenu.match(didactiquesRegExp)) {
          currentData = exo.contenu.replace(exo.contenu.match(didactiquesRegExp)[0], '')
        } else {
          currentData = `\n${exo.contenu}`
        }
        currentData += '%Correction'
        currentData += `\n${exo.contenuCorrection}`
        zip.folder('exercices')?.file('ex' + i + '.tex', currentData)
        i += 1
      }
    }
  })
  switch (latexFileInfos.style) {
    case 'PfMCan':
    case 'PfMFiche':
      codeTex += '\n\t\\end{Maquette}\n\\clearpage\n}\n\\end{document}'
      break
    case 'PfMBeamer':
      // Pour Beamer, gestion de l'option double colonne
      if (latexFileInfos.beamerTwocolumn) {
        codeTex += '\n\\end{multicols}'
      }
      codeTex += '\n\t\\end{Maquette}\n\\end{document}'
      break
    case 'PfMAmc':
      // Pour AMC, gestion de l'option double colonne
      if (latexFileInfos.twocolumn) {
        codeTex += '\n\\end{multicols}'
      }
      codeTex += '\n\t\\end{Maquette}\n}\n}\n\\end{document}'
      break
    default:
      console.info(`Cas ${latexFileInfos.style} non prévu pour le style LaTeX`)
  }
  // zip.file('main.tex', text)
  zip.file('main.tex', codeTex)
  // Des fichiers suplémentaires au besoin
  if (!isBacIn(latexExos)) {
    // console.info('Macros supplémentaires sauf pour le bac.')
  } else {
    // console.info('Macros supplémentaires pour le bac.')
  }
  // ========================================================================
  // J'enlève pour le moment en ligne pour ne pas livrer le code
  // Je laisse des exemples génériques pour mémoire
  // zip.file('PfCStuffTeX.tex', PfCStuffTeX)
  // zip.file('PfCStuffMP.mp', PfCStuffMP)
  // ========================================================================
  // zip.file('ProfCollege.sty', PfC)
  // zip.file('ProfMaquette.sty', PfM)
  // ========================================================================
  //
  // On ajoute des fichiers de commandes spécifiques
  zip.folder('commandes')?.file('styleKawasakiFicheAmc.tex', styleKawasakiFicheAmc, { binary: false })
  zip.folder('commandes')?.file('paquetsAdditionnels.tex', paquetsAdditionnels, { binary: false })
  zip.folder('commandes')?.file('cedricPierquet.tex', cedricPierquet, { binary: false })
  zip.folder('commandes')?.file('christophePoulain.tex', christophePoulain, { binary: false })
  zip.folder('commandes')?.file('sebastienLozano.tex', sebastienLozano, { binary: false })
  if (latexFileInfos.style === 'PfMBeamer') {
    zip.folder('commandes')?.file('pourBeamer.tex', pourBeamer, { binary: false })
  }
  if (latexFileInfos.style === 'PfMAmc') {
    zip.folder('commandes')?.file('pourAmc.tex', pourAmc, { binary: false })
    zip.file('listeElevesFactice.csv', listeElevesFactice, { binary: false })
  }
  // Fin ajout fichiers commandes spécifiques
  // On ajoute les images si il y en a
  if (withImages) {
    const urls = buildImagesUrlsList(exosContentList, picsNames)
    urls.forEach(function (url) {
      const splitUrl = url.split('/')
      const fileName = splitUrl[splitUrl.length - 1]
      console.log(url)
      zip.folder('images')?.file(fileName, urlToPromise(url), 'arraybuffer')
    })
  }
  // On ajoute les dats si il y en a
  if (withDats) {
    const urlsDats = buildDatsUrlsList(exosContentList, datsNames)
    urlsDats.forEach(function (url) {
      const splitUrl = url.split('/')
      const fileName = splitUrl[splitUrl.length - 1]
      zip.folder('')?.file(fileName, urlToPromise(url), { binary: true })
    })
  }
  // On ajoute les fichiers pythons si il y en a
  if (withPythons) {
    const urlsPythons = buildPythonsUrlsList(exosContentList, pythonsNames)
    // console.log(urlsPythons)
    urlsPythons.forEach(function (url) {
      const splitUrl = url.split('/')
      const fileName = splitUrl[splitUrl.length - 1]
      zip.folder('')?.file(fileName, urlToPromise(url), { binary: true })
    })
  }
  // // On génère l'archive
  zip.generateAsync({ type: 'blob' }).then(function (content) {
    saveAs(content, zipFileName)
  })
}
